<template>
  <div class="login-container">
    <Card title="Forgot Password" hide-collapse width="90%" max-width="550px">
      <v-form v-model="isValid" @submit.prevent="forgotPassword">
        <v-text-field
          v-model="email"
          type="email"
          label="Enter Your Email"
          :rules="rules"
        ></v-text-field>

        <p class="text-center mt-3">
          <v-btn
            type="submit"
            color="secondary"
            :disabled="loading || !isValid"
            :loading="loading"
            >Submit</v-btn
          >
        </p>
      </v-form>
    </Card>
  </div>
</template>

<script>
import { validateEmail } from "@/helpers";
export default {
  name: "forgot-password",
  data() {
    return {
      isValid: false,
      loading: false,
      email: "",
      rules: [
        (value) => !!value || "Required.",
        (value) => !!validateEmail(value) || "Email is invalid",
      ],
    };
  },
  methods: {
    async forgotPassword() {
      try {
        this.loading = true;
        await this.axios.post(`/reset/password`, {
          email: this.email,
        });
        this.$router.push("/");
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: grid;
  place-items: center;

  background-image: url("../../assets/login_background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
